import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

body{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 99.2vw;
  font-family: 'Lato', sans-serif;

  color: white;

}
`;
